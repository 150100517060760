"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceImagesToJSON = exports.VenueResourceImagesFromJSONTyped = exports.VenueResourceImagesFromJSON = void 0;
const VenueResourceDetails_1 = require("./VenueResourceDetails");
function VenueResourceImagesFromJSON(json) {
    return VenueResourceImagesFromJSONTyped(json, false);
}
exports.VenueResourceImagesFromJSON = VenueResourceImagesFromJSON;
function VenueResourceImagesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'original': json['original'],
        'thumbnail': json['thumbnail'],
        'description': json['description'],
        'details': (0, VenueResourceDetails_1.VenueResourceDetailsFromJSON)(json['details']),
    };
}
exports.VenueResourceImagesFromJSONTyped = VenueResourceImagesFromJSONTyped;
function VenueResourceImagesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'original': value.original,
        'thumbnail': value.thumbnail,
        'description': value.description,
        'details': (0, VenueResourceDetails_1.VenueResourceDetailsToJSON)(value.details),
    };
}
exports.VenueResourceImagesToJSON = VenueResourceImagesToJSON;
