"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilteredVenuesResourceRoomsToJSON = exports.FilteredVenuesResourceRoomsFromJSONTyped = exports.FilteredVenuesResourceRoomsFromJSON = void 0;
function FilteredVenuesResourceRoomsFromJSON(json) {
    return FilteredVenuesResourceRoomsFromJSONTyped(json, false);
}
exports.FilteredVenuesResourceRoomsFromJSON = FilteredVenuesResourceRoomsFromJSON;
function FilteredVenuesResourceRoomsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'benches': json['benches'],
        'uShaped': json['u_shaped'],
        'standing': json['standing'],
        'banquet': json['banquet'],
    };
}
exports.FilteredVenuesResourceRoomsFromJSONTyped = FilteredVenuesResourceRoomsFromJSONTyped;
function FilteredVenuesResourceRoomsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'benches': value.benches,
        'u_shaped': value.uShaped,
        'standing': value.standing,
        'banquet': value.banquet,
    };
}
exports.FilteredVenuesResourceRoomsToJSON = FilteredVenuesResourceRoomsToJSON;
