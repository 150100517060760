"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourcePlaceItemsToJSON = exports.VenueResourcePlaceItemsFromJSONTyped = exports.VenueResourcePlaceItemsFromJSON = void 0;
function VenueResourcePlaceItemsFromJSON(json) {
    return VenueResourcePlaceItemsFromJSONTyped(json, false);
}
exports.VenueResourcePlaceItemsFromJSON = VenueResourcePlaceItemsFromJSON;
function VenueResourcePlaceItemsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'ready': json['ready'],
    };
}
exports.VenueResourcePlaceItemsFromJSONTyped = VenueResourcePlaceItemsFromJSONTyped;
function VenueResourcePlaceItemsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'ready': value.ready,
    };
}
exports.VenueResourcePlaceItemsToJSON = VenueResourcePlaceItemsToJSON;
