import { Route, Routes } from 'react-router-dom'
import Home from './components/Home/HomeComponent'
import Header from './layout/Header/Header'
import Footer from './layout/Footer/Footer'
import ListingComponent from 'components/Listing/ListingComponent'
import DetailComponent from 'components/Detail/DetailComponent'
import LoginComponent from 'components/Login/LoginComponent'
import VerifyComponent from 'components/Verify/VerifyComponent'
import SubscriptionFee from 'components/SubscriptionFee/SubscriptionFee'
import Advertisement from 'components/Advertisement/Advertisement'
import './i18n'

import { ToastContainer } from 'react-toastify'
import ForgottenPasswordComponent from 'components/ForgottenPassword/ForgottenPasswordComponent'
import RequestComponent from 'components/Request/RequestComponent'
import ResetPasswordComponent from 'components/ResetPassword/ResetPasswordComponent'
import SpecialOfferListingComponent from 'components/SpecialOfferListing/SpecialOfferListingComponent'

function App () {
  return (
    <div className="App">
      <ToastContainer
        autoClose={2000}
        position="top-center"
      />
      <div id="main">
        <Header />
        <div id="wrapper">
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/listing" element={<ListingComponent />} />
              <Route path="/specail-offer-listing" element={<SpecialOfferListingComponent />} />
              <Route path="/:venueSlug" element={<DetailComponent />} />
              <Route path="/request" element={<RequestComponent />} />
              <Route path="/verify" element={< VerifyComponent/>} />
              <Route path="/reset-password" element={< ResetPasswordComponent/>} />
              <Route path="/subscriptionfee" element={< SubscriptionFee/>} />
              <Route path="/advertisement" element={< Advertisement/>} />

            </Routes>
          </div>
        </div>
        <Footer />
        <LoginComponent />
        <ForgottenPasswordComponent />
      </div>
    </div>
  )
}

export default App
