"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecialOfferResourceDataToJSON = exports.SpecialOfferResourceDataFromJSONTyped = exports.SpecialOfferResourceDataFromJSON = void 0;
function SpecialOfferResourceDataFromJSON(json) {
    return SpecialOfferResourceDataFromJSONTyped(json, false);
}
exports.SpecialOfferResourceDataFromJSON = SpecialOfferResourceDataFromJSON;
function SpecialOfferResourceDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'description': json['description'],
        'name': json['name'],
        'venueSlug': json['venue_slug'],
        'venueName': json['venue_name'],
        'city': json['city'],
        'highlightedimage': json['highlightedimage'],
        'eventTimeFrom': json['event_time_from'],
        'eventTimeTo': json['event_time_to'],
    };
}
exports.SpecialOfferResourceDataFromJSONTyped = SpecialOfferResourceDataFromJSONTyped;
function SpecialOfferResourceDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'description': value.description,
        'name': value.name,
        'venue_slug': value.venueSlug,
        'venue_name': value.venueName,
        'city': value.city,
        'highlightedimage': value.highlightedimage,
        'event_time_from': value.eventTimeFrom,
        'event_time_to': value.eventTimeTo,
    };
}
exports.SpecialOfferResourceDataToJSON = SpecialOfferResourceDataToJSON;
