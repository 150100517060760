"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceDetailsToJSON = exports.VenueResourceDetailsFromJSONTyped = exports.VenueResourceDetailsFromJSON = void 0;
const VenueResourceDetailsOriginal_1 = require("./VenueResourceDetailsOriginal");
function VenueResourceDetailsFromJSON(json) {
    return VenueResourceDetailsFromJSONTyped(json, false);
}
exports.VenueResourceDetailsFromJSON = VenueResourceDetailsFromJSON;
function VenueResourceDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'original': (0, VenueResourceDetailsOriginal_1.VenueResourceDetailsOriginalFromJSON)(json['original']),
        'thumbnail': (0, VenueResourceDetailsOriginal_1.VenueResourceDetailsOriginalFromJSON)(json['thumbnail']),
    };
}
exports.VenueResourceDetailsFromJSONTyped = VenueResourceDetailsFromJSONTyped;
function VenueResourceDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'original': (0, VenueResourceDetailsOriginal_1.VenueResourceDetailsOriginalToJSON)(value.original),
        'thumbnail': (0, VenueResourceDetailsOriginal_1.VenueResourceDetailsOriginalToJSON)(value.thumbnail),
    };
}
exports.VenueResourceDetailsToJSON = VenueResourceDetailsToJSON;
