"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationSuccessfulToJSON = exports.RegistrationSuccessfulFromJSONTyped = exports.RegistrationSuccessfulFromJSON = void 0;
const UserResource_1 = require("./UserResource");
function RegistrationSuccessfulFromJSON(json) {
    return RegistrationSuccessfulFromJSONTyped(json, false);
}
exports.RegistrationSuccessfulFromJSON = RegistrationSuccessfulFromJSON;
function RegistrationSuccessfulFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'user': (0, UserResource_1.UserResourceFromJSON)(json['user']),
        'token': json['token'],
    };
}
exports.RegistrationSuccessfulFromJSONTyped = RegistrationSuccessfulFromJSONTyped;
function RegistrationSuccessfulToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'user': (0, UserResource_1.UserResourceToJSON)(value.user),
        'token': value.token,
    };
}
exports.RegistrationSuccessfulToJSON = RegistrationSuccessfulToJSON;
