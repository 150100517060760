"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceSpecialOffersToJSON = exports.VenueResourceSpecialOffersFromJSONTyped = exports.VenueResourceSpecialOffersFromJSON = void 0;
const VenueResourcePlaceItems_1 = require("./VenueResourcePlaceItems");
function VenueResourceSpecialOffersFromJSON(json) {
    return VenueResourceSpecialOffersFromJSONTyped(json, false);
}
exports.VenueResourceSpecialOffersFromJSON = VenueResourceSpecialOffersFromJSON;
function VenueResourceSpecialOffersFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'highlighted': json['highlighted'],
        'eventTimeFrom': json['event_time_from'],
        'eventTimeTo': json['event_time_to'],
        'eventType': (json['event_type'].map(VenueResourcePlaceItems_1.VenueResourcePlaceItemsFromJSON)),
        'type': (json['type'].map(VenueResourcePlaceItems_1.VenueResourcePlaceItemsFromJSON)),
        'category': (json['category'].map(VenueResourcePlaceItems_1.VenueResourcePlaceItemsFromJSON)),
    };
}
exports.VenueResourceSpecialOffersFromJSONTyped = VenueResourceSpecialOffersFromJSONTyped;
function VenueResourceSpecialOffersToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'highlighted': value.highlighted,
        'event_time_from': value.eventTimeFrom,
        'event_time_to': value.eventTimeTo,
        'event_type': (value.eventType.map(VenueResourcePlaceItems_1.VenueResourcePlaceItemsToJSON)),
        'type': (value.type.map(VenueResourcePlaceItems_1.VenueResourcePlaceItemsToJSON)),
        'category': (value.category.map(VenueResourcePlaceItems_1.VenueResourcePlaceItemsToJSON)),
    };
}
exports.VenueResourceSpecialOffersToJSON = VenueResourceSpecialOffersToJSON;
