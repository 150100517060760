import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import 'rc-slider/assets/index.css'
import classNames from 'classnames'
import SelectWidget from 'widgets/SelectWidget'
import useFilterType from 'hooks/useFilterType'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import DatePicker from 'react-datepicker'

import ContentLoader from 'react-content-loader'

import 'react-datepicker/dist/react-datepicker.css'

const SpecialOfferListingComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const PlaceLoader = (props) => (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={440}
      viewBox="0 0 300 400"
      {...props}
    >
      <circle cx="31" cy="31" r="15" />
      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
    </ContentLoader>
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const [filteredSpecialOffers, setFilteredSpecialOffers] = useState(null)

  const EventTypeFilter = searchParams.get('eventtype')
  const EventCategoryFilter = searchParams.get('eventcategory')
  const EventFilter = searchParams.get('type')
  const eventStart = searchParams.get('eventstart') ?? new Date()
  const [firstStart, setFirstStart] = useState(true)

  useEffect(() => {
    if (firstStart) {
      window.scrollTo(0, 0)
      setFirstStart(false)
    }
  }, [firstStart])

  useEffect(() => {
    async function fetchData () {
      const response = await dispatch.venue.getFilteredSpecialOffers(searchParams.entries())
      setFilteredSpecialOffers(response.data)
    }
    fetchData()
  }, [dispatch, searchParams])

  const navigateToDetail = (venueSlug, id) => {
    navigate('/' + venueSlug + '?specialoffer=' + id)
  }

  const updateParams = (e, target) => {
    const currentParams = {}

    for (const entry of searchParams.entries()) {
      if (!(entry[0] in currentParams)) currentParams[entry[0]] = []
      currentParams[entry[0]].push(entry[1])
    }

    if (e === null) {
      delete currentParams[target]
      setSearchParams(currentParams)
    } else {
      const newParams = { ...currentParams, [target]: (typeof e.length !== 'undefined') ? e.map((item) => item.value) : e.value }
      setSearchParams(newParams)
    }
  }

  const {

    offerEventType,
    offerEventCategory,
    offerType
  } = useFilterType()

  const eventTypeDefault = offerEventType.filter(filter => filter.value === EventTypeFilter)

  const eventCategoryDefault = offerEventCategory.filter(filter => filter.value === EventCategoryFilter)

  const typeDefault = offerType.filter(filter => filter.value === EventFilter)

  return (
    <>

      <section className="grey-blue-bg pt-5">
        <div className="container">
          <div className="row custom-form w-100">
            {/* filter sidebar */}
            <div className="col-md-4">
              <div className="fl-wrap filter-sidebar_item fixed-bar">
                <div className="filter-sidebar fl-wrap lws_mobile">
                  {/* col-list-search-input-item */}
                  <div className="col-list-search-input-item">
                    <label>{t('common:filter-eventDate')}</label>
                  </div>
                  <DatePicker minDate={new Date()} selected={new Date(eventStart)} onChange={(date) => updateParams({ value: new Date(date).toISOString().slice(0, 10) }, 'eventstart')} />

                  <div className="col-list-search-input-item">
                    <label>{t('common:filter-eventType')}</label>
                  </div>
                  <SelectWidget
                    placeholder={t('common:all')}
                    value={eventTypeDefault}
                    icon={<i className="fal fa-map-marker text-yellow"></i>}
                    options={offerEventType}
                    isClearable={(e) => updateParams(e, 'eventtype')}
                    onChange={(e) => updateParams(e, 'eventtype')}
                  />

                  <div className="col-list-search-input-item mt-4">
                    <label>{t('common:filter-offerCategory')}</label>
                  </div>
                  <SelectWidget
                    placeholder={t('common:all')}
                    value={eventCategoryDefault}
                    icon={<i className="fal fa-map-marker text-yellow"></i>}
                    options={offerEventCategory}
                    isClearable={(e) => updateParams(e, 'eventcategory')}
                    onChange={(e) => updateParams(e, 'eventcategory')}
                  />

                  <div className="col-list-search-input-item mt-4">
                    <label>{t('common:filter-offerType')}</label>
                  </div>
                  <SelectWidget
                    placeholder={t('common:all')}
                    value={typeDefault}
                    icon={<i className="fal fa-map-marker text-yellow"></i>}
                    options={offerType}
                    isClearable={(e) => updateParams(e, 'type')}
                    onChange={(e) => updateParams(e, 'type')}
                  />
                </div>
              </div>
            </div>
            {/* filter sidebar end */}
            {/* listing */}
            <div className="col-md-8 pt-4 pt-md-0">
              {/* col-list-wrap */}
              <div className="col-list-wrap fw-col-list-wrap post-container">
                {/* list-main-wrap */}
                <div className="list-main-wrap fl-wrap card-listing">
                  {/* list-main-wrap-opt */}
                  <div className="list-main-wrap-opt fl-wrap">

                    { filteredSpecialOffers !== null &&
                    <div className="section-title" >
                      <h2>{t('common:menu.offers')} {filteredSpecialOffers.length}  {t('common:piece')}</h2>
                    </div>
                    }
                    {/* price-opt */}

                    {/* price-opt end */}
                  </div>
                  {/* list-main-wrap-opt end */}
                  {/* listing-item-container */}
                  <div className="container-fluid px-2">
                    <div className="row pt-3">
                      { filteredSpecialOffers === null ? <div className="listing-item col-lg-6"><PlaceLoader /></div>
                        : (filteredSpecialOffers.length === 0 ? <div>{t('listing:no-results')}</div> : filteredSpecialOffers.map((place) => {
                          return (
                            <div key={place.id} className={classNames('listing-item col-lg-6 has_one_column')}>
                              <article className="geodir-category-listing fl-wrap" >
                                <div className="geodir-category-img">
                                  <div className="special-offer-container">
                                    <div className="offer-name">
                                      <strong>{place.venueName}</strong>
                                    </div>
                                  </div>

                                  <img className="cursor-pointer" onClick={() => navigateToDetail(place.venueSlug, place.id)} src={place.highlightedimage} alt="" />
                                  <div className="geodir-category-opt">
                                  </div>
                                </div>
                                <div className="geodir-category-content fl-wrap  d-flex flex-column justify-content-between" >
                                  <div className="geodir-category-content-title fl-wrap">
                                    <div className="geodir-category-content-title-item">
                                      <h3 className="title-sin_map cursor-pointer" onClick={() => navigateToDetail(place.venueSlug, place.id)}>{place.name} </h3>
                                      <div className="geodir-category-location fl-wrap"><a className="map-item"><i className="fas fa-map-marker-alt"></i>{place.city}</a>

                                        <div className="float-end special-offer-date">
                                          {place.eventTimeFrom} - {place.eventTimeTo}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <p className="highlited-description" style={{ width: '100%', padding: 0, marginBottom: 5 }}>{place.description}</p>
                                  </div>
                                  <div className="geodir-category-footer">
                                    <div className="geodir-opt-list d-flex" style={{ justifyContent: 'end' }}>
                                      <a className="cursor-pointer" onClick={() => navigateToDetail(place.venueSlug, place.id)} ><span>{t('common:more')}</span></a>

                                    </div>
                                  </div>
                                </div>

                              </article>
                            </div>
                          )
                        }))}
                    </div>
                  </div>
                  {/* listing-item-container end */}
                </div>
                {/* list-main-wrap end */}
              </div>
              {/* col-list-wrap end */}
            </div>
            {/* listing  end */}
          </div>
          {/* row end */}
        </div>
        <div className="limit-box fl-wrap"></div>
      </section>
    </>
  )
}

export default SpecialOfferListingComponent
