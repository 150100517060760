"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClickContactDataToJSON = exports.ClickContactDataFromJSONTyped = exports.ClickContactDataFromJSON = void 0;
const runtime_1 = require("../runtime");
function ClickContactDataFromJSON(json) {
    return ClickContactDataFromJSONTyped(json, false);
}
exports.ClickContactDataFromJSON = ClickContactDataFromJSON;
function ClickContactDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'venueId': !(0, runtime_1.exists)(json, 'venue_id') ? undefined : json['venue_id'],
    };
}
exports.ClickContactDataFromJSONTyped = ClickContactDataFromJSONTyped;
function ClickContactDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'venue_id': value.venueId,
    };
}
exports.ClickContactDataToJSON = ClickContactDataToJSON;
