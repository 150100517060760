"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserResourceToJSON = exports.UserResourceFromJSONTyped = exports.UserResourceFromJSON = void 0;
function UserResourceFromJSON(json) {
    return UserResourceFromJSONTyped(json, false);
}
exports.UserResourceFromJSON = UserResourceFromJSON;
function UserResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'email': json['email'],
        'emailVerifiedAt': json['email_verified_at'],
    };
}
exports.UserResourceFromJSONTyped = UserResourceFromJSONTyped;
function UserResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'email_verified_at': value.emailVerifiedAt,
    };
}
exports.UserResourceToJSON = UserResourceToJSON;
