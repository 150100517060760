"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenuesApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class VenuesApi extends runtime.BaseAPI {
    /**
     * Save request offer
     */
    clickBannerRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/banner`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.ClickBannerToJSON)(requestParameters.clickBanner),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ReadyResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Save request offer
     */
    clickBanner(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clickBannerRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Save request offer
     */
    clickContactDataRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/click-contact-data`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.ClickContactDataToJSON)(requestParameters.clickContactData),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ReadyResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Save request offer
     */
    clickContactData(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.clickContactDataRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Banners
     */
    getBannersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/banners`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.BannerResourceFromJSON));
        });
    }
    /**
     * Get Banners
     */
    getBanners(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getBannersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Filtered Special Offers
     */
    getFilteredSpecialOffersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.params !== undefined) {
                queryParameters['params'] = requestParameters.params;
            }
            const headerParameters = {};
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/filtered-special-offers`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.SpecialOfferResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Get Filtered Special Offers
     */
    getFilteredSpecialOffers(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getFilteredSpecialOffersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Venues
     */
    getFilteredVenuesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.params !== undefined) {
                queryParameters['params'] = requestParameters.params;
            }
            const headerParameters = {};
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/filteredvenues`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.FilteredVenuesResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Get Venues
     */
    getFilteredVenues(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getFilteredVenuesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Highlighted Special Offer
     */
    getHighSpecialOffersRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/high-special-offers`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.SpecialOffersResourceFromJSON));
        });
    }
    /**
     * Get Highlighted Special Offer
     */
    getHighSpecialOffers(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHighSpecialOffersRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Request Venues
     */
    getRequestVenuesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.params !== undefined) {
                queryParameters['params'] = requestParameters.params;
            }
            const headerParameters = {};
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/requestvenues`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.FilteredVenuesResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Request Venues
     */
    getRequestVenues(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getRequestVenuesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Special Places
     */
    getSpecialPlacesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/specialplaces`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.SpeciaPlaceResourceFromJSON));
        });
    }
    /**
     * Get Special Places
     */
    getSpecialPlaces(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getSpecialPlacesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Venue
     */
    getVenueRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.venueSlug === null || requestParameters.venueSlug === undefined) {
                throw new runtime.RequiredError('venueSlug', 'Required parameter requestParameters.venueSlug was null or undefined when calling getVenue.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/venue/{venue_slug}`.replace(`{${"venue_slug"}}`, encodeURIComponent(String(requestParameters.venueSlug))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.VenueResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Get Venue
     */
    getVenue(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getVenueRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Venues
     */
    getVenuesRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.highlighted !== undefined) {
                queryParameters['highlighted'] = requestParameters.highlighted;
            }
            if (requestParameters.page !== undefined) {
                queryParameters['page'] = requestParameters.page;
            }
            if (requestParameters.limit !== undefined) {
                queryParameters['limit'] = requestParameters.limit;
            }
            const headerParameters = {};
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/venues`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.VenuesResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Get Venues
     */
    getVenues(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getVenuesRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Save request offer
     */
    saveRequestOfferRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
                headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
            }
            const response = yield this.request({
                path: `/api/requestoffer`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.PostRequestOfferToJSON)(requestParameters.postRequestOffer),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ReadyResourceFromJSON)(jsonValue));
        });
    }
    /**
     * Save request offer
     */
    saveRequestOffer(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.saveRequestOfferRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.VenuesApi = VenuesApi;
