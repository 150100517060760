"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilteredVenuesResourceDataToJSON = exports.FilteredVenuesResourceDataFromJSONTyped = exports.FilteredVenuesResourceDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const FilteredVenuesResourceAddress_1 = require("./FilteredVenuesResourceAddress");
const FilteredVenuesResourceLocation_1 = require("./FilteredVenuesResourceLocation");
const FilteredVenuesResourceRooms_1 = require("./FilteredVenuesResourceRooms");
function FilteredVenuesResourceDataFromJSON(json) {
    return FilteredVenuesResourceDataFromJSONTyped(json, false);
}
exports.FilteredVenuesResourceDataFromJSON = FilteredVenuesResourceDataFromJSON;
function FilteredVenuesResourceDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'venueSlug': json['venue_slug'],
        'name': json['name'],
        'address': (0, FilteredVenuesResourceAddress_1.FilteredVenuesResourceAddressFromJSON)(json['address']),
        'description': json['description'],
        'highlightedimage': json['highlightedimage'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'bannerId': !(0, runtime_1.exists)(json, 'banner_id') ? undefined : json['banner_id'],
        'rooms': (0, FilteredVenuesResourceRooms_1.FilteredVenuesResourceRoomsFromJSON)(json['rooms']),
        'location': (0, FilteredVenuesResourceLocation_1.FilteredVenuesResourceLocationFromJSON)(json['location']),
    };
}
exports.FilteredVenuesResourceDataFromJSONTyped = FilteredVenuesResourceDataFromJSONTyped;
function FilteredVenuesResourceDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'venue_slug': value.venueSlug,
        'name': value.name,
        'address': (0, FilteredVenuesResourceAddress_1.FilteredVenuesResourceAddressToJSON)(value.address),
        'description': value.description,
        'highlightedimage': value.highlightedimage,
        'url': value.url,
        'banner_id': value.bannerId,
        'rooms': (0, FilteredVenuesResourceRooms_1.FilteredVenuesResourceRoomsToJSON)(value.rooms),
        'location': (0, FilteredVenuesResourceLocation_1.FilteredVenuesResourceLocationToJSON)(value.location),
    };
}
exports.FilteredVenuesResourceDataToJSON = FilteredVenuesResourceDataToJSON;
