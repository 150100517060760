"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourcePlaceToJSON = exports.VenueResourcePlaceFromJSONTyped = exports.VenueResourcePlaceFromJSON = void 0;
const VenueResourcePlaceEvents_1 = require("./VenueResourcePlaceEvents");
function VenueResourcePlaceFromJSON(json) {
    return VenueResourcePlaceFromJSONTyped(json, false);
}
exports.VenueResourcePlaceFromJSON = VenueResourcePlaceFromJSON;
function VenueResourcePlaceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'feature': json['feature'],
        'description': json['description'],
        'events': (json['events'].map(VenueResourcePlaceEvents_1.VenueResourcePlaceEventsFromJSON)),
    };
}
exports.VenueResourcePlaceFromJSONTyped = VenueResourcePlaceFromJSONTyped;
function VenueResourcePlaceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'feature': value.feature,
        'description': value.description,
        'events': (value.events.map(VenueResourcePlaceEvents_1.VenueResourcePlaceEventsToJSON)),
    };
}
exports.VenueResourcePlaceToJSON = VenueResourcePlaceToJSON;
