"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecialOfferResourceMetaToJSON = exports.SpecialOfferResourceMetaFromJSONTyped = exports.SpecialOfferResourceMetaFromJSON = void 0;
function SpecialOfferResourceMetaFromJSON(json) {
    return SpecialOfferResourceMetaFromJSONTyped(json, false);
}
exports.SpecialOfferResourceMetaFromJSON = SpecialOfferResourceMetaFromJSON;
function SpecialOfferResourceMetaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currentPage': json['current_page'],
        'from': json['from'],
        'lastPage': json['last_page'],
        'perPage': json['per_page'],
        'to': json['to'],
        'total': json['total'],
    };
}
exports.SpecialOfferResourceMetaFromJSONTyped = SpecialOfferResourceMetaFromJSONTyped;
function SpecialOfferResourceMetaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'current_page': value.currentPage,
        'from': value.from,
        'last_page': value.lastPage,
        'per_page': value.perPage,
        'to': value.to,
        'total': value.total,
    };
}
exports.SpecialOfferResourceMetaToJSON = SpecialOfferResourceMetaToJSON;
