"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilteredVenuesResourceLocationToJSON = exports.FilteredVenuesResourceLocationFromJSONTyped = exports.FilteredVenuesResourceLocationFromJSON = void 0;
function FilteredVenuesResourceLocationFromJSON(json) {
    return FilteredVenuesResourceLocationFromJSONTyped(json, false);
}
exports.FilteredVenuesResourceLocationFromJSON = FilteredVenuesResourceLocationFromJSON;
function FilteredVenuesResourceLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lat': json['lat'],
        'lng': json['lng'],
    };
}
exports.FilteredVenuesResourceLocationFromJSONTyped = FilteredVenuesResourceLocationFromJSONTyped;
function FilteredVenuesResourceLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lat': value.lat,
        'lng': value.lng,
    };
}
exports.FilteredVenuesResourceLocationToJSON = FilteredVenuesResourceLocationToJSON;
