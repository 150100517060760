"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceToJSON = exports.VenueResourceFromJSONTyped = exports.VenueResourceFromJSON = void 0;
const runtime_1 = require("../runtime");
const VenueResourceAccessibility_1 = require("./VenueResourceAccessibility");
const VenueResourceAccommedation_1 = require("./VenueResourceAccommedation");
const VenueResourceCatering_1 = require("./VenueResourceCatering");
const VenueResourceConferenceRoom_1 = require("./VenueResourceConferenceRoom");
const VenueResourceContact_1 = require("./VenueResourceContact");
const VenueResourceImages_1 = require("./VenueResourceImages");
const VenueResourceLocation_1 = require("./VenueResourceLocation");
const VenueResourcePlace_1 = require("./VenueResourcePlace");
const VenueResourceSpecialOffers_1 = require("./VenueResourceSpecialOffers");
function VenueResourceFromJSON(json) {
    return VenueResourceFromJSONTyped(json, false);
}
exports.VenueResourceFromJSON = VenueResourceFromJSON;
function VenueResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'venueSlug': json['venue_slug'],
        'name': json['name'],
        'programs': json['programs'],
        'contact': (0, VenueResourceContact_1.VenueResourceContactFromJSON)(json['contact']),
        'accessibility': !(0, runtime_1.exists)(json, 'accessibility') ? undefined : (0, VenueResourceAccessibility_1.VenueResourceAccessibilityFromJSON)(json['accessibility']),
        'place': (0, VenueResourcePlace_1.VenueResourcePlaceFromJSON)(json['place']),
        'images': (json['images'].map(VenueResourceImages_1.VenueResourceImagesFromJSON)),
        'highlightedimage': json['highlightedimage'],
        'accommedation': (0, VenueResourceAccommedation_1.VenueResourceAccommedationFromJSON)(json['accommedation']),
        'conferenceRoom': (0, VenueResourceConferenceRoom_1.VenueResourceConferenceRoomFromJSON)(json['conference_room']),
        'catering': (0, VenueResourceCatering_1.VenueResourceCateringFromJSON)(json['catering']),
        'location': (0, VenueResourceLocation_1.VenueResourceLocationFromJSON)(json['location']),
        'specialOffers': !(0, runtime_1.exists)(json, 'special_offers') ? undefined : (json['special_offers'].map(VenueResourceSpecialOffers_1.VenueResourceSpecialOffersFromJSON)),
    };
}
exports.VenueResourceFromJSONTyped = VenueResourceFromJSONTyped;
function VenueResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'venue_slug': value.venueSlug,
        'name': value.name,
        'programs': value.programs,
        'contact': (0, VenueResourceContact_1.VenueResourceContactToJSON)(value.contact),
        'accessibility': (0, VenueResourceAccessibility_1.VenueResourceAccessibilityToJSON)(value.accessibility),
        'place': (0, VenueResourcePlace_1.VenueResourcePlaceToJSON)(value.place),
        'images': (value.images.map(VenueResourceImages_1.VenueResourceImagesToJSON)),
        'highlightedimage': value.highlightedimage,
        'accommedation': (0, VenueResourceAccommedation_1.VenueResourceAccommedationToJSON)(value.accommedation),
        'conference_room': (0, VenueResourceConferenceRoom_1.VenueResourceConferenceRoomToJSON)(value.conferenceRoom),
        'catering': (0, VenueResourceCatering_1.VenueResourceCateringToJSON)(value.catering),
        'location': (0, VenueResourceLocation_1.VenueResourceLocationToJSON)(value.location),
        'special_offers': value.specialOffers === undefined ? undefined : (value.specialOffers.map(VenueResourceSpecialOffers_1.VenueResourceSpecialOffersToJSON)),
    };
}
exports.VenueResourceToJSON = VenueResourceToJSON;
