"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceContactToJSON = exports.VenueResourceContactFromJSONTyped = exports.VenueResourceContactFromJSON = void 0;
const runtime_1 = require("../runtime");
function VenueResourceContactFromJSON(json) {
    return VenueResourceContactFromJSONTyped(json, false);
}
exports.VenueResourceContactFromJSON = VenueResourceContactFromJSON;
function VenueResourceContactFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'city': json['city'],
        'address': json['address'],
        'contactPerson': json['contact_person'],
        'phone': !(0, runtime_1.exists)(json, 'phone') ? undefined : json['phone'],
        'mobile': !(0, runtime_1.exists)(json, 'mobile') ? undefined : json['mobile'],
        'corporateWebsite': !(0, runtime_1.exists)(json, 'corporate_website') ? undefined : json['corporate_website'],
    };
}
exports.VenueResourceContactFromJSONTyped = VenueResourceContactFromJSONTyped;
function VenueResourceContactToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'city': value.city,
        'address': value.address,
        'contact_person': value.contactPerson,
        'phone': value.phone,
        'mobile': value.mobile,
        'corporate_website': value.corporateWebsite,
    };
}
exports.VenueResourceContactToJSON = VenueResourceContactToJSON;
