"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceConferenceRoomRoomsToJSON = exports.VenueResourceConferenceRoomRoomsFromJSONTyped = exports.VenueResourceConferenceRoomRoomsFromJSON = void 0;
function VenueResourceConferenceRoomRoomsFromJSON(json) {
    return VenueResourceConferenceRoomRoomsFromJSONTyped(json, false);
}
exports.VenueResourceConferenceRoomRoomsFromJSON = VenueResourceConferenceRoomRoomsFromJSON;
function VenueResourceConferenceRoomRoomsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'floorSpace': json['floor_space'],
        'benches': json['benches'],
        'uShaped': json['u_shaped'],
        'standing': json['standing'],
        'banquet': json['banquet'],
        'clima': json['clima'],
        'dimmable': json['dimmable'],
        'lightOfDay': json['light_of_day'],
        'internet': json['internet'],
    };
}
exports.VenueResourceConferenceRoomRoomsFromJSONTyped = VenueResourceConferenceRoomRoomsFromJSONTyped;
function VenueResourceConferenceRoomRoomsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'floor_space': value.floorSpace,
        'benches': value.benches,
        'u_shaped': value.uShaped,
        'standing': value.standing,
        'banquet': value.banquet,
        'clima': value.clima,
        'dimmable': value.dimmable,
        'light_of_day': value.lightOfDay,
        'internet': value.internet,
    };
}
exports.VenueResourceConferenceRoomRoomsToJSON = VenueResourceConferenceRoomRoomsToJSON;
