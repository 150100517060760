import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams } from 'react-router-dom'

const ResetPasswordComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const token = searchParams.get('token')
  const email = searchParams.get('email')

  const [request, setRequest] = useState({
    email,
    token,
    password: '',
    passwordConfirmation: ''
  })

  const [error, setError] = useState({

    password: '',
    passwordConfirmation: ''
  })

  const validateInput = e => {
    const { name, value } = e.target
    setError(prev => {
      const stateObj = { ...prev, [name]: '' }

      switch (name) {
      case 'passwordConfirmation':
        if (request.password && value !== request.password) {
          stateObj[name] = t('login:config-password-not-match')
        }
        break

      default:
        break
      }

      return stateObj
    })
  }

  const onInputChange = e => {
    const { name, value } = e.target
    setRequest(prev => ({
      ...prev,
      [name]: value
    }))
    validateInput(e)
  }

  const handleResetPasswordRequest = (event) => {
    async function fetchData () {
      const response = await dispatch.auth.resetPassword(request)
      if (typeof response.errors !== 'undefined' || typeof response.exception !== 'undefined') {
        if (typeof response.errors.errorCode !== 'undefined' && typeof response.errors.field !== 'undefined' && typeof response.errors.errorMessage !== 'undefined') { toast.warning(response.errors.errorMessage) } else
        if (typeof response.errors.errorCode !== 'undefined') { toast.warning(t('messages:' + response.errors.errorCode)) } else { toast.warning(response.message ?? response.errors.message) }
      } else {
        navigate('/')
        toast.success(t('common:new-pasword-set'))
      }
    }
    event.preventDefault()
    fetchData()
  }

  return (
    <section>
      <div className="container toast.warnin">
        <div className="col-md-5 main-register m-auto" style={{ float: 'unset' }}>
          <h3>{t('common:reset-password')} <br/><span>rendezvény<strong>szervező</strong></span></h3>
          <div className="custom-form">
            <form onSubmit={handleResetPasswordRequest} className="main-register-form" id="main-register-form2">

              <label>{t('login:email-address')} </label>
              <input name="email" type="email" value={email} disabled/>
              <label className="form-required"> {t('login:password')} </label>
              <input name="password" type="password" value={request.password} onChange={onInputChange} required/>
              <label className="form-required" >{t('login:password_confirmation')} </label>
              <input name="passwordConfirmation" type="password" value={request.passwordConfirmation}
                onChange={onInputChange} required />

              {error.passwordConfirmation && <div><span className="error">{error.passwordConfirmation}</span></div>}
              <button type="submit" className="log-submit-btn color-bg mb-3" ><span>{t('common:modify-password')}</span></button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResetPasswordComponent
