
// https://codesandbox.io/s/rzdhd?file=/examples/ComponentToPrint/index.js:4609-4625
import { useTranslation } from 'react-i18next'
import SelectWidget from 'widgets/SelectWidget'
import { useEffect, useRef, useState } from 'react'
import InputNumber from 'rc-input-number'
import ReactToPrint from 'react-to-print'

const SubscriptionFee = () => {
  const componentRef = useRef()
  const componentRef2 = useRef()
  const { t } = useTranslation()

  const [numofVenues, setnumofVenues] = useState(1)
  const [numofAdvertisement, setNumofAdvertisement] = useState(1)
  const [venueFilter, setVenueFilter] = useState([{
    id: 0,
    capacity: 0,
    accomodation: 0,
    location: 0,
    sites: [true, true, true, true, true]
  },
  {
    id: 1,
    capacity: 0,
    accomodation: 0,
    location: 0,
    sites: [true, true, true, true, true]
  },
  {
    id: 2,
    capacity: 0,
    accomodation: 0,
    location: 0,
    sites: [true, true, true, true, true]
  }])

  const siteTypes = [
    'rendezvenyszervezo.com',
    'rendezvenyhelyszinek.com',
    'eskuvohelyszinek.com',
    'konferenciahelyszinek.com',
    'treninghelyszinek.com'
  ]

  const capacityTypes = [
    { value: 1, label: '50 fő alatt' },
    { value: 2, label: '50-100 fő között' },
    { value: 3, label: '100 fő felett' }
  ]
  const accomodationTypes = [
    { value: 1, label: 'Szállás nélkül' },
    { value: 2, label: 'Szállással' }
  ]
  const locationTypes = [
    { value: 1, label: 'A fővároson és Pest megyén kívül' },
    { value: 2, label: 'Budapesten vagy Pest megyében' }
  ]

  /// /advertisement

  const [advertisementFilter, setAdvertisementFilter] = useState([{
    id: 0,
    type: 0,
    month: 0,
    sites: [true, true, true, true, true]
  },
  {
    id: 1,
    type: 0,
    month: 0,
    sites: [true, true, true, true, true]
  },
  {
    id: 2,
    type: 0,
    month: 0,
    sites: [true, true, true, true, true]
  }])

  const advertisementTypes = [
    { value: 1, label: 'Felső' },
    { value: 2, label: 'Kiemelt' },
    { value: 3, label: 'Lebegő' },
    { value: 4, label: 'Főoldali listaelem' },
    { value: 5, label: 'Kereső oldalsó' },
    { value: 6, label: 'Kereső listaelem' },
    { value: 7, label: 'Alsó' }
  ]

  const monthTypes = [
    { value: 1, label: '1 hónap' },
    { value: 2, label: '3 hónap' },
    { value: 3, label: '6 hónap' }
  ]

  useEffect(() => {
    setVenueFilter(venueFilter.map((venue) =>
      venue.id >= numofVenues
        ? {
          ...venue,
          capacity: 0,
          accomodation: 0,
          location: 0,
          sites: [true, true, true, true, true]
        }
        : { ...venue }
    ))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numofVenues])

  useEffect(() => {
    setAdvertisementFilter(advertisementFilter.map((advertisement) =>
      advertisement.id >= numofAdvertisement
        ? {
          ...advertisement,
          type: 0,
          month: 0,
          sites: [true, true, true, true, true]
        }
        : { ...advertisement }
    ))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numofAdvertisement])

  // VENUES

  //* arr[elhelyezkedés][méret][szállás][futamido]
  const arr = []

  arr[1111] = 20000; arr[1112] = 32000
  arr[1121] = 25000; arr[1122] = 40000
  arr[1211] = 30000; arr[1212] = 48000
  arr[1221] = 40000; arr[1222] = 64000
  arr[1311] = 50000; arr[1312] = 80000
  arr[1321] = 60000; arr[1322] = 96000
  arr[2111] = 25000; arr[2112] = 40000
  arr[2121] = 30000; arr[2122] = 48000
  arr[2211] = 40000; arr[2212] = 64000
  arr[2221] = 50000; arr[2222] = 80000
  arr[2311] = 60000; arr[2312] = 96000
  arr[2321] = 70000; arr[2322] = 112000

  const kedvezmeny = []
  kedvezmeny[0] = 0
  kedvezmeny[1] = 0
  kedvezmeny[2] = 10
  kedvezmeny[3] = 20
  kedvezmeny[4] = 30
  kedvezmeny[5] = 40

  const sumPrice = (year) => {
    let value = 0;
    [...Array(siteTypes.length)].forEach((element, key) => {
      value += calcPrice(key, year)
    })

    return value
  }

  const calcAllowance = () => {
    const value = [0, 0, 0];
    [...Array(numofVenues)].forEach((element, key) => {
      const item = venueFilter[key];
      [...Array(siteTypes.length)].forEach((element, site) => {
        if (item.location > 0 && item.sites[site] === true) {
          const keycalc = item.location.toString() + item.capacity.toString() + item.accomodation.toString() + '1'
          value[key] += site === 0 ? arr[keycalc] : arr[keycalc] / 2
        }
      })
    })

    const kedv = kedvezmeny[venueFilter[0].sites.filter(site => site === true).length]
    const kedv2 = kedvezmeny[venueFilter[1].sites.filter(site => site === true).length]
    const kedv3 = kedvezmeny[venueFilter[2].sites.filter(site => site === true).length]
    // const kedvc = kedv * kedv2 * kedv3 !== 0 ? (kedv * value[0] + (kedv2 * value[1] || 0) + (kedv3 * value[2] || 0)) / (value[0] + value[1] + value[2]) || 0 : 0
    const kedvc = (kedv * value[0] + (kedv2 * value[1] || 0) + (kedv3 * value[2] || 0)) / (value[0] + value[1] + value[2]) || 0

    return kedvc
  }

  const calcPrice = (site, year) => {
    let value = 0;
    [...Array(numofVenues)].forEach((element, key) => {
      const item = venueFilter[key]
      if (item.location > 0 && item.sites[site] === true) {
        const keycalc = item.location.toString() + item.capacity.toString() + item.accomodation.toString() + year.toString()
        value += site === 0 ? arr[keycalc] : arr[keycalc] / 2
      }
    })
    return value
  }
  const allowence = calcAllowance()

  const activeVenues = venueFilter.filter(venue => venue.location > 0 &&
  (venue.sites[0] === true || venue.sites[1] === true || venue.sites[2] === true || venue.sites[4] === true || venue.sites[3] === true)).length

  const venueNumAllowence = activeVenues > 1 ? Math.round((activeVenues) * 1000) / 100 : 0

  const priceFormat = (price, year = 0) => {
    return price.toLocaleString() + (year === 0 ? ' Ft' : (year === 1 ? ' Ft/év' : ' Ft/2 év'))
  }

  const sumPrice1 = sumPrice(1)
  const sumPrice2 = sumPrice(2)

  /// ADVERTISEMENTS

  const advertisementArr = []

  advertisementArr[11] = 20000; advertisementArr[12] = 45000; advertisementArr[13] = 60000
  advertisementArr[21] = 100000; advertisementArr[22] = 270000; advertisementArr[23] = 480000
  advertisementArr[31] = 50000; advertisementArr[32] = 120000; advertisementArr[33] = 180000
  advertisementArr[41] = 25000; advertisementArr[42] = 60000; advertisementArr[43] = 90000
  advertisementArr[51] = 50000; advertisementArr[52] = 120000; advertisementArr[53] = 180000
  advertisementArr[61] = 50000; advertisementArr[62] = 120000; advertisementArr[63] = 180000
  advertisementArr[71] = 40000; advertisementArr[72] = 90000; advertisementArr[73] = 120000

  const calcAdPrice = (site) => {
    let value = 0;
    [...Array(numofAdvertisement)].forEach((element, key) => {
      const item = advertisementFilter[key]
      if (item.month > 0 && item.sites[site] === true) {
        const keycalc = item.type.toString() + item.month.toString()
        value += site === 0 ? advertisementArr[keycalc] : advertisementArr[keycalc] / 2
      }
    })
    return value || 0
  }

  const calcAdAllowance = () => {
    const value = [0, 0, 0];
    [...Array(numofAdvertisement)].forEach((element, key) => {
      const item = advertisementFilter[key];
      [...Array(siteTypes.length)].forEach((element, site) => {
        if (item.month > 0 && item.sites[site] === true) {
          const keycalc = item.type.toString() + item.month.toString()
          value[key] += site === 0 ? advertisementArr[keycalc] : advertisementArr[keycalc] / 2
        }
      })
    })
    const kedv = kedvezmeny[advertisementFilter[0].sites.filter(site => site === true).length]
    const kedv2 = kedvezmeny[advertisementFilter[1].sites.filter(site => site === true).length]
    const kedv3 = kedvezmeny[advertisementFilter[2].sites.filter(site => site === true).length]
    // const kedvc = kedv * kedv2 * kedv3 !== 0 ? (kedv * value[0] + (kedv2 * value[1] || 0) + (kedv3 * value[2] || 0)) / (value[0] + value[1] + value[2]) || 0 : 0

    const kedvc = (kedv * value[0] + (kedv2 * value[1] || 0) + (kedv3 * value[2] || 0)) / (value[0] + value[1] + value[2]) || 0

    return kedvc
  }

  const adAllowence = calcAdAllowance()

  const sumAdPrice = () => {
    let value = 0;
    [...Array(siteTypes.length)].forEach((element, key) => {
      value += calcAdPrice(key)
    })

    return value
  }

  const activeAdvertisement = advertisementFilter.filter(advertisement => advertisement.month > 0 &&
    (advertisement.sites[0] === true || advertisement.sites[1] === true || advertisement.sites[2] === true || advertisement.sites[4] === true || advertisement.sites[3] === true)).length

  const advertisementNumAllowence = activeAdvertisement > 1 ? Math.round((activeAdvertisement) * 1000) / 100 : 0

  return (
    <div className="content">

      <section className="middle-padding gre y-blue-bg">
        <div className="container">
          <div className="bookiing-form-wrap">

            <div className="section-title pb-5"><h2>{t('subscriptionfee:title')}</h2>
              <span className="section-separator"></span>
            </div>

            <div className="border p-2" ref={componentRef}>

              <div className="section-title text-start">
                <h3>{t('subscriptionfee:basicpackage')}</h3>
              </div>

              <div className="row">

                <div className="col-sm-6 text-start">
                  <label className="pe-3">Rendezvényhelyszínek száma</label>

                  <InputNumber value={numofVenues} defaultValue={1} min={1} max={3} onChange={num =>
                    setnumofVenues(num)} />

                </div>

                <div className="row m-0 mt-1">
                  {
                    [...Array(numofVenues)].map((element, key) => {
                      return <div key={key} className="col-sm-4 border p-2 mt-2">
                        <label >Maximális rendezvénykapacitás</label>
                        <SelectWidget
                          className="mb-2"
                          defaultValue={venueFilter[key].capacity}
                          placeholder="Maximális rendezvénykapacitás"
                          options={capacityTypes}
                          onChange={e =>
                            setVenueFilter(venueFilter.map((venue) =>
                              venue.id === key
                                ? { ...venue, capacity: parseInt(e.value) }
                                : { ...venue }
                            ))
                          }
                        />

                        {venueFilter[key].capacity !== 0 &&
                         <>
                           <label>{t('request:accommodation')}</label>
                           <SelectWidget
                             className="mb-2"
                             defaultValue={venueFilter[key].accomodation}
                             placeholder="Szálláslehetőség"
                             options={accomodationTypes}
                             onChange={e =>
                               setVenueFilter(venueFilter.map((venue) =>
                                 venue.id === key
                                   ? { ...venue, accomodation: parseInt(e.value) }
                                   : { ...venue }
                               ))
                             }
                           />
                         </>
                        }

                        {venueFilter[key].accomodation !== 0 &&
                         <>
                           <label>Elhelyezkedés</label>
                           <SelectWidget
                             className="mb-2"
                             defaultValue={venueFilter[key].location}
                             placeholder="Elhelyezkedés"
                             options={locationTypes}
                             onChange={e =>
                               setVenueFilter(venueFilter.map((venue) =>
                                 venue.id === key
                                   ? { ...venue, location: parseInt(e.value) }
                                   : { ...venue }
                               ))
                             }
                           />
                         </>
                        }

                        { venueFilter[key].location !== 0 &&
                  <div className="row m-0 mt-1">
                    <div >
                      <label>Melyik portálunkra szeretne előfizetni?</label>
                      <div>
                        { siteTypes.map((site, siteKey) =>
                          <div key={(siteKey)} className="fl-wrap filter-tags text-start mt-1">
                            <input type="checkbox" defaultChecked={venueFilter[key].sites[siteKey] }
                              onChange={e =>
                                setVenueFilter(venueFilter.map((venue) =>
                                  venue.id === key
                                    ? {
                                      ...venue,
                                      sites: venueFilter[key].sites.map((cSite, cSiteKey) => {
                                        if (siteKey === cSiteKey) {
                                          return e.target.checked
                                        } else { return cSite }
                                      })
                                    }
                                    : { ...venue }
                                ))
                              }

                            />
                            <label htmlFor="check-a" className="text-gray m-0" style={{ minWidth: 180 }}>{site}</label>
                          </div>
                        )

                        }
                      </div>
                    </div>
                  </div>
                        }

                      </div>
                    })
                  }
                </div>

                <div className="tablepadding">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" ></th>
                        <th scope="col"style={{ width: '20%' }} >1 év</th>
                        <th scope="col"style={{ width: '20%' }} >2 év</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th >rendezvenyszervezo.com alapcsomagra történő regisztráció:</th>
                        <td>{priceFormat(calcPrice(0, 1), 1)}</td>
                        <td>{priceFormat(calcPrice(0, 2), 2)}</td>
                      </tr>
                      <tr>
                        <th >rendezvenyhelyszinek.com alapcsomagra történő regisztráció:</th>
                        <td>{priceFormat(calcPrice(1, 1), 1)}</td>
                        <td>{priceFormat(calcPrice(1, 2), 2)}</td>
                      </tr>
                      <tr>
                        <th >eskuvohelyszinek.com alapcsomagra történő regisztráció:</th>
                        <td>{priceFormat(calcPrice(2, 1), 1)}</td>
                        <td>{priceFormat(calcPrice(2, 2), 2)}</td>
                      </tr>
                      <tr>
                        <th >konferenciahelyszinek.com alapcsomagra történő regisztráció:</th>
                        <td>{priceFormat(calcPrice(3, 1), 1)}</td>
                        <td>{priceFormat(calcPrice(3, 2), 2)}</td>
                      </tr>
                      <tr>
                        <th >treninghelyszinek.com alapcsomagra történő regisztráció:</th>
                        <td>{priceFormat(calcPrice(4, 1), 1)}</td>
                        <td>{priceFormat(calcPrice(4, 2), 2)}</td>
                      </tr>
                      <tr>
                        <th >Listaárak összesen:</th>
                        <td>{priceFormat(sumPrice1, 1)}</td>
                        <td>{priceFormat(sumPrice2, 2)}</td>
                      </tr>
                      <tr>
                        <th >Több portálra egyszerre történő regisztráció miatti kedvezmény:</th>
                        <td style={{ color: 'red' }}>{ Math.round(allowence * 100) / 100 + '%'}</td>
                        <td style={{ color: 'red' }}>{ Math.round(allowence * 100) / 100 + '%'}</td>
                      </tr>
                      <tr>
                        <th >Kedvezmény összege:</th>
                        <td style={{ color: 'red' }}>{priceFormat(Math.round(sumPrice1 * allowence / 100), 1) }</td>
                        <td style={{ color: 'red' }}>{priceFormat(Math.round(sumPrice2 * allowence / 100), 2) }</td>
                      </tr>
                      { venueNumAllowence > 0 &&
                      <>
                        <tr>
                          <th >Több rendezvényhelyszínnel / szolgáltatási profilban egyszerre történő regisztráció miatti kedvezmény:</th>
                          <td style={{ color: 'red' }}>{ venueNumAllowence + '%'}</td>
                          <td style={{ color: 'red' }}>{ venueNumAllowence + '%'}</td>
                        </tr>
                        <tr>
                          <th >Kedvezmény összege:</th>
                          <td style={{ color: 'red' }}>{priceFormat((Math.round(sumPrice1 - Math.round(sumPrice1 * allowence / 100)) * venueNumAllowence / 100), 1) }</td>
                          <td style={{ color: 'red' }}>{priceFormat((Math.round(sumPrice2 - Math.round(sumPrice2 * allowence / 100)) * venueNumAllowence / 100), 2) }</td>
                        </tr>
                      </>
                      }

                      <tr>
                        <th >Kedvezményes előfizetési díj:</th>
                        <td>{priceFormat(sumPrice1 - Math.round(sumPrice1 * allowence / 100) - (Math.round(sumPrice1 - Math.round(sumPrice1 * allowence / 100)) * venueNumAllowence / 100), 1)}</td>
                        <td>{priceFormat(sumPrice2 - Math.round(sumPrice2 * allowence / 100) - (Math.round(sumPrice2 - Math.round(sumPrice2 * allowence / 100)) * venueNumAllowence / 100), 2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>

              <div className="printable" >  <img style={{ width: '100%' }} src={'/szerz.jpg'} alt="Logo" /></div>
            </div>
            {venueFilter[0].location !== 0 &&
            <ReactToPrint
              trigger={() => <button className="map-show-button" style={{ marginTop: 10 }}>Kalkuláció nyomtatása</button>}
              content={() => componentRef.current}
            />}
            {/* ADVERTISEMENT */}
            <div className="border p-2 mt-5" ref={componentRef2} >
              <div className="section-title text-start">
                <h3>{t('subscriptionfee:advertisements')}</h3>
              </div>

              <div className="row">

                <div className="col-sm-6 text-start">
                  <label className="pe-3">Kiemelések száma</label>

                  <InputNumber value={numofAdvertisement} defaultValue={1} min={1} max={3} onChange={num =>
                    setNumofAdvertisement(num)} />

                </div>

                <div className="row m-0 mt-1">
                  {
                    [...Array(numofAdvertisement)].map((element, key) => {
                      return <div key={key} className="col-sm-4 border p-2 mt-2">
                        <label >Hirdetés típusa</label>
                        <SelectWidget
                          className="mb-2"
                          defaultValue={advertisementFilter[key].type}
                          placeholder="Hirdetés típusa"
                          options={advertisementTypes}
                          onChange={e =>
                            setAdvertisementFilter(advertisementFilter.map((advertisement) =>
                              advertisement.id === key
                                ? { ...advertisement, type: parseInt(e.value) }
                                : { ...advertisement }
                            ))
                          }
                        />

                        {advertisementFilter[key].type !== 0 &&
                         <>
                           <label>Előfizetés időtartama</label>
                           <SelectWidget
                             className="mb-2"
                             defaultValue={advertisementFilter[key].month}
                             placeholder="Előfizetés időtartama"
                             options={monthTypes}
                             onChange={e =>
                               setAdvertisementFilter(advertisementFilter.map((advertisement) =>
                                 advertisement.id === key
                                   ? { ...advertisement, month: parseInt(e.value) }
                                   : { ...advertisement }
                               ))
                             }
                           />
                         </>
                        }

                        { advertisementFilter[key].month !== 0 &&
                  <div className="row m-0 mt-1">
                    <div >
                      <label>Melyik portálunkra szeretne előfizetni?</label>
                      <div>
                        { siteTypes.map((site, siteKey) =>
                          <div key={(siteKey)} className="fl-wrap filter-tags text-start mt-1">
                            <input type="checkbox" defaultChecked={advertisementFilter[key].sites[siteKey] }
                              onChange={e =>
                                setAdvertisementFilter(advertisementFilter.map((advertisement) =>
                                  advertisement.id === key
                                    ? {
                                      ...advertisement,
                                      sites: advertisementFilter[key].sites.map((cSite, cSiteKey) => {
                                        if (siteKey === cSiteKey) {
                                          return e.target.checked
                                        } else { return cSite }
                                      })
                                    }
                                    : { ...advertisement }
                                ))
                              }

                            />
                            <label htmlFor="check-a" className="text-gray m-0" style={{ minWidth: 180 }}>{site}</label>
                          </div>
                        )

                        }
                      </div>
                    </div>
                  </div>
                        }

                      </div>
                    })
                  }
                </div>

                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" ></th>
                        <th scope="col"style={{ width: '20%' }} >Előfizetési díj</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th >rendezvenyszervezo.com kiválasztott kiemelt hirdetésekre történő előfizetés:</th>
                        <td>{priceFormat(calcAdPrice(0))}</td>
                      </tr>
                      <tr>
                        <th >rendezvenyhelyszinek.com kiválasztott kiemelt hirdetésekre történő előfizetés:</th>
                        <td>{priceFormat(calcAdPrice(1))}</td>
                      </tr>
                      <tr>
                        <th >eskuvohelyszinek.com kiválasztott kiemelt hirdetésekre történő előfizetés:</th>
                        <td>{priceFormat(calcAdPrice(2))}</td>
                      </tr>
                      <tr>
                        <th >konferenciahelyszinek.com kiválasztott kiemelt hirdetésekre történő előfizetés:</th>
                        <td>{priceFormat(calcAdPrice(3))}</td>
                      </tr>
                      <tr>
                        <th >treninghelyszinek.com kiválasztott kiemelt hirdetésekre történő előfizetés:</th>
                        <td>{priceFormat(calcAdPrice(4))}</td>
                      </tr>
                      <tr>
                        <th >Listaárak összesen:</th>
                        <td>{priceFormat(sumAdPrice())}</td>
                      </tr>
                      <tr>
                        <th >Több portálra egyszerre történő regisztráció miatti kedvezmény:</th>
                        <td style={{ color: 'red' }}>{ Math.round(adAllowence * 100) / 100 + '%'}</td>
                      </tr>
                      <tr>
                        <th >Kedvezmény összege:</th>
                        <td style={{ color: 'red' }}>{priceFormat(Math.round(sumAdPrice() * adAllowence / 100)) }</td>
                      </tr>
                      { advertisementNumAllowence > 0 &&
                      <>
                        <tr>
                          <th >Több rendezvényhelyszínnel / szolgáltatási profilban egyszerre történő regisztráció miatti kedvezmény:</th>
                          <td style={{ color: 'red' }}>{ advertisementNumAllowence + '%'}</td>
                        </tr>
                        <tr>
                          <th >Kedvezmény összege:</th>
                          <td style={{ color: 'red' }}>{priceFormat((Math.round(sumAdPrice() - Math.round(sumAdPrice() * adAllowence / 100)) * advertisementNumAllowence / 100)) }</td>
                        </tr>
                      </>
                      }

                      <tr>
                        <th >Kedvezményes előfizetési díj:</th>
                        <td>{priceFormat(sumAdPrice() - Math.round(sumAdPrice() * adAllowence / 100) - (Math.round(sumAdPrice() - Math.round(sumAdPrice() * adAllowence / 100)) * advertisementNumAllowence / 100))}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>

              <div className="printable" >  <img style={{ width: '100%' }} src={'/szerz.jpg'} alt="Logo" /></div>
            </div>
            {advertisementFilter[0].month !== 0 &&
            <ReactToPrint
              trigger={() => <button className="map-show-button" style={{ marginTop: 10 }}>Kalkuláció nyomtatása</button>}
              content={() => componentRef2.current}
            />}

          </div>
        </div>
      </section>
    </div>
  )
}

export default SubscriptionFee
