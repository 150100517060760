import { createModel } from '@rematch/core'
import { filterApi } from 'services/api'
const filter = createModel()({

  state: {
    cityTypes: [],
    regionTypes: []

  },
  reducers: {
    setStateCityTypes (state, payload) {
      state.cityTypes = payload
    },
    setStateRegionTypes (state, payload) {
      state.regionTypes = payload
    }
  },
  effects: (dispatch) => ({
    async getCityTypes () {
      const response = await filterApi.getFilterCities()
      dispatch.filter.setStateCityTypes(response)
    },
    async getRegionTypes () {
      const response = await filterApi.getFilterRegions()
      dispatch.filter.setStateRegionTypes(response)
    }
  })
})

export default filter
