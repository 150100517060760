"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpeciaPlaceResourceToJSON = exports.SpeciaPlaceResourceFromJSONTyped = exports.SpeciaPlaceResourceFromJSON = void 0;
function SpeciaPlaceResourceFromJSON(json) {
    return SpeciaPlaceResourceFromJSONTyped(json, false);
}
exports.SpeciaPlaceResourceFromJSON = SpeciaPlaceResourceFromJSON;
function SpeciaPlaceResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'slug': json['slug'],
        'description': json['description'],
        'image': json['image'],
    };
}
exports.SpeciaPlaceResourceFromJSONTyped = SpeciaPlaceResourceFromJSONTyped;
function SpeciaPlaceResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'slug': value.slug,
        'description': value.description,
        'image': value.image,
    };
}
exports.SpeciaPlaceResourceToJSON = SpeciaPlaceResourceToJSON;
