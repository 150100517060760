"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VenueResourceAccessibilityToJSON = exports.VenueResourceAccessibilityFromJSONTyped = exports.VenueResourceAccessibilityFromJSON = void 0;
function VenueResourceAccessibilityFromJSON(json) {
    return VenueResourceAccessibilityFromJSONTyped(json, false);
}
exports.VenueResourceAccessibilityFromJSON = VenueResourceAccessibilityFromJSON;
function VenueResourceAccessibilityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'car': json['car'],
        'bus': json['bus'],
        'train': json['train'],
    };
}
exports.VenueResourceAccessibilityFromJSONTyped = VenueResourceAccessibilityFromJSONTyped;
function VenueResourceAccessibilityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'car': value.car,
        'bus': value.bus,
        'train': value.train,
    };
}
exports.VenueResourceAccessibilityToJSON = VenueResourceAccessibilityToJSON;
