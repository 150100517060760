"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserChangePasswordToJSON = exports.UserChangePasswordFromJSONTyped = exports.UserChangePasswordFromJSON = void 0;
function UserChangePasswordFromJSON(json) {
    return UserChangePasswordFromJSONTyped(json, false);
}
exports.UserChangePasswordFromJSON = UserChangePasswordFromJSON;
function UserChangePasswordFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'oldPassword': json['old_password'],
        'password': json['password'],
        'passwordConfirmation': json['password_confirmation'],
    };
}
exports.UserChangePasswordFromJSONTyped = UserChangePasswordFromJSONTyped;
function UserChangePasswordToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'old_password': value.oldPassword,
        'password': value.password,
        'password_confirmation': value.passwordConfirmation,
    };
}
exports.UserChangePasswordToJSON = UserChangePasswordToJSON;
