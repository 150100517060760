"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CityFilterResourceOptionsToJSON = exports.CityFilterResourceOptionsFromJSONTyped = exports.CityFilterResourceOptionsFromJSON = void 0;
function CityFilterResourceOptionsFromJSON(json) {
    return CityFilterResourceOptionsFromJSONTyped(json, false);
}
exports.CityFilterResourceOptionsFromJSON = CityFilterResourceOptionsFromJSON;
function CityFilterResourceOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'value': json['value'],
        'label': json['label'],
    };
}
exports.CityFilterResourceOptionsFromJSONTyped = CityFilterResourceOptionsFromJSONTyped;
function CityFilterResourceOptionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'value': value.value,
        'label': value.label,
    };
}
exports.CityFilterResourceOptionsToJSON = CityFilterResourceOptionsToJSON;
