"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Rendezvény API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecialOffersResourceToJSON = exports.SpecialOffersResourceFromJSONTyped = exports.SpecialOffersResourceFromJSON = void 0;
const runtime_1 = require("../runtime");
function SpecialOffersResourceFromJSON(json) {
    return SpecialOffersResourceFromJSONTyped(json, false);
}
exports.SpecialOffersResourceFromJSON = SpecialOffersResourceFromJSON;
function SpecialOffersResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'venueSlug': json['venue_slug'],
        'venueName': json['venue_name'],
        'city': json['city'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'bannerId': !(0, runtime_1.exists)(json, 'banner_id') ? undefined : json['banner_id'],
        'highlightedimage': json['highlightedimage'],
        'eventTimeFrom': json['event_time_from'],
        'eventTimeTo': json['event_time_to'],
    };
}
exports.SpecialOffersResourceFromJSONTyped = SpecialOffersResourceFromJSONTyped;
function SpecialOffersResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'venue_slug': value.venueSlug,
        'venue_name': value.venueName,
        'city': value.city,
        'url': value.url,
        'banner_id': value.bannerId,
        'highlightedimage': value.highlightedimage,
        'event_time_from': value.eventTimeFrom,
        'event_time_to': value.eventTimeTo,
    };
}
exports.SpecialOffersResourceToJSON = SpecialOffersResourceToJSON;
