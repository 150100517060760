import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'

const VerifyComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const hash = searchParams.get('hash')
  const id = searchParams.get('id')
  const [verified, setVerified] = useState(null)
  const { loggedInUser } = useSelector((state) => state.auth)
  useEffect(() => {
    const verify = async () => {
      try {
        if (id && hash && !loggedInUser?.emailVerifiedAt) {
          const response = await dispatch.auth.verify({ id, hash })

          if (typeof response.errors !== 'undefined' || typeof response.exception !== 'undefined') {
            setVerified(false)
            if (typeof response.errors.errorCode !== 'undefined' && typeof response.errors.field !== 'undefined' && typeof response.errors.errorMessage !== 'undefined') { toast.warning(response.errors.errorMessage) } else
            if (typeof response.errors.errorCode !== 'undefined') { toast.warning(t('messages:' + response.errors.errorCode)) } else { toast.warning(response.message ?? response.errors.message) }
          } else {
            setVerified(true)
          }
        }
      } catch (e) {
        setVerified(false)
      }
    }

    verify()
  }, [t, dispatch, id, hash, loggedInUser?.emailVerifiedAt])

  return (
    <section>
      { verified !== null
        ? <div>
          <span className={classNames('verify-text', { error: !verified, ready: verified })}>{verified != null && verified ? t('common:verify-success') : t('common:verify-error')}</span>
        </div>
        : <div>
          <span className={classNames('verify-text')}>{ t('common:verify-in-progress') }</span>
        </div>
      }
    </section>

  )
}

export default VerifyComponent
